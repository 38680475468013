import requests from '../../request'

const baseUrl = '/system/merchant';
const selectorBaseUrl = '/selector';
const region = 'cloud-api';

export function selector(params) {
  return requests(
    {
      methods:'get',
      url: selectorBaseUrl + '/menu',
      params,
    },
    {
      region,
    }
  );
}

// 获取行业
export function industry() {
  return requests(
    {
      methods:'get',
      url: '/selector/industry',
    },
    {
      region,
    }
  );
}

/**
 * 查询列表
 */
export function query(params) {
  return requests(
    {
      methods:'get',
      url: baseUrl + '/merch/query',
      params,
    },
    {
      region,
    }
  );
}

/**
 * 根据ID查询
 * @param id
 */
export function get(id) {
  return requests(
    {
      methods:'get',
      url: baseUrl + '/merch/detail',
      params: {
        id,
      },
    },
    {
      region,
    }
  );
}

/**
 * 根据merchId查询配置信息
 * @param merchId
 */
export function getConfiguration(merchId) {
  return requests(
    {
      methods:'get',
      url: baseUrl + '/merchConfig/detail',
      params: {
        merchId,
      },
    },
    {
      region,
    }
  );
}

/**
 * 修改
 * @param vo
 */
export function update(vo) {
  return requests(
    {
      methods:'post',
      url: baseUrl + '/merch/update',
      data: vo,
    },
    {
      region,
    }
  );
}

/**
 * 修改配置信息
 * @param vo
 */
export function updateConfiguration(vo) {
  return requests(
    {
      methods:'post',
      url: baseUrl + '/merchConfig/update',
      data: vo,
    },
    {
      region,
    }
  );
}

/**
 * 根据ID删除
 * @param id
 */
// export function deleteById(id) {
//   return defHttp.delete(
//     {
//       url: baseUrl,
//       data: {
//         id: id,
//       },
//     },
//     {
//       region,
//     }
//   );
// }

/**
 * 批量启用
 * @param ids
 */
// export function batchEnable(ids) {
//   return defHttp.patch(
//     {
//       url: baseUrl + '/enable/batch',
//       data: ids,
//     },
//     {
//       region,
//     }
//   );
// }

/**
 * 批量停用
 * @param ids
 */
// export function batchUnable(ids) {
//   return defHttp.patch(
//     {
//       url: baseUrl + '/unable/batch',
//       data: ids,
//     },
//     {
//       region,
//     }
//   );
// }