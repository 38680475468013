<template>
  <div id="add">
    <van-form>
      <van-cell-group inset>
        <van-cell @click="showStockSelect">
          <van-field
            autocomplete="off"
            v-model="form.scName"
            required
            name="scName"
            label="仓库"
        /></van-cell>
        <!-- 需加上会员和销售员选择弹窗 -->
        <!-- <van-cell @click="showSupplierSelect"> -->
        <!-- <van-cell>
          <van-field
           autocomplete="off"
            :required="retailConfig.retailOutSheetRequireMember"
            v-model="form.retailOutSheetRequireMember"
            name="retailOutSheetRequireMember"
            label="会员"
            :readonly="curType === 'detail'"
        /></van-cell>
        <van-cell>
          <van-field
           autocomplete="off"
            v-model="form.salerId"
            name="salerId"
            label="销售员"
            :readonly="curType === 'detail'"
        /></van-cell> -->
        <van-cell @click="openDateTimePicker('form', 'paymentDate')">
          <van-field
            autocomplete="off"
            v-model="form.paymentDate"
            required
            name="paymentDate"
            label="付款日期"
        /></van-cell>
        <van-cell @click="retailOutSelectVisible = true">
          <van-field
            autocomplete="off"
            v-model="form.outSheetId"
            name="outSheetId"
            required
            label="零售出库单"
        /></van-cell>
      </van-cell-group>
      <van-form style="margin: 16px">
        <div class="action">
          <Van-icon
            name="add-o"
            type="primary"
            @click="toShowProductSelect"
          ></Van-icon>
        </div>
        <CustomTable
          :scrollWidth="600"
          :showPagination="false"
          :columns="columns"
          :tableData="tableData"
        ></CustomTable>
      </van-form>

      <div style="margin: 16px">
        <van-form>
          <van-cell>合计</van-cell>
          <van-cell-group>
            <van-cell
              ><van-field
                autocomplete="off"
                label="采购数量"
                readonly
                v-model="form.totalNum"
                type="number"
            /></van-cell>
            <van-cell
              ><van-field
                autocomplete="off"
                label="赠品数量"
                readonly
                type="number"
                v-model="form.giftNum"
            /></van-cell>
            <van-cell
              ><van-field
                autocomplete="off"
                label="含税总金额"
                readonly
                type="number"
                v-model="form.totalAmount"
            /></van-cell>
          </van-cell-group>
        </van-form>
        <!-- 约定支付 -->
        <PayType
          ref="payType"
          type="add"
          style="margin: 10px 0"
          :list="tableData2"
        ></PayType>
        <!-- 备注 -->
        <van-field
          autocomplete="off"
          v-model="form.description"
          disabled
          rows="2"
          autosize
          label="备注"
          type="textarea"
        />
        <van-cell class="actions-btn">
          <van-button type="primary" @click="onAddConfirm" round block
            >保存</van-button
          >
          <van-button
            type="info"
            @click="toDirectApprovePass"
            round
            block
            v-permission="'retail:return:approve'"
            >直接审批通过</van-button
          >
          <van-button @click="close" type="default" round block
            >关闭</van-button
          >
        </van-cell>
      </div>
    </van-form>
    <ProductSelect
      type="retail"
      columnsType="checkbox"
      :scId="this.form.scId"
      :visible="showProductSelect"
      @confirm="onSelectProductConfirm"
    ></ProductSelect>
    <DateTimePicker
      value-format="yyyy-MM-dd"
      :showPicker="showDatePicker"
      @confirm="onDatePickerConfirm"
      @cancel="showDatePicker = false"
    ></DateTimePicker>
    <StockSelect
      :visible="stockSelectVisible"
      @confirm="stockSelectConfirm"
    ></StockSelect>
    <RetailOutSheetSelect
      :visible="retailOutSelectVisible"
      @confirm="retailOutSelectConfirm"
    ></RetailOutSheetSelect>
  </div>
</template>

<script>
import { create, directApprovePass } from "../../../service/api/retail/return";
import ProductSelect from "../../../components/productSelect.vue";
import StockSelect from "../../../components/stockSelect.vue";
import CustomTable from "../../../components/customTable.vue";
import DateTimePicker from "../../../components/dateTimePicker.vue";
import PayType from "../../../components/payType.vue";
import { Notify, Toast, Dialog } from "vant";
import moment from "moment";
import { getConfiguration } from "../../../service/api/system/merchant";
import * as configApi from "../../../service/api/retail/config";
import RetailOutSheetSelect from "../../../components/retailOutSheetSelect.vue";
import { getPaymentDate, getWithReturn } from "../../../service/api/retail/out";
import { getOrderPayType } from "../../../service/api/payType";
export default {
  name: "index",
  components: {
    CustomTable,
    ProductSelect,
    StockSelect,
    RetailOutSheetSelect,
    DateTimePicker,
    PayType,
  },
  data() {
    return {
      retailConfig: {},
      showDatePicker: false,
      showDatePicker: false,
      stockSelectVisible: false,
      retailOutSelectVisible: false,
      showProductSelect: false,
      datePickType: "product",
      form: {},
      tableData: [],
      tableData2: [],
      columns: [
        {
          field: "productName",
          key: "productName",
          title: "商品名称",
          width: 100,
          fixed: "left",
        },
        {
          field: "productCode",
          key: "productCode",
          title: "商品编号",
          width: 80,
        },
        { field: "unit", key: "unit", title: "单位", width: 80 },
        { field: "spec", key: "spec", title: "规格", width: 80 },
        {
          field: "categoryName",
          key: "categoryName",
          title: "商品类目",
          width: 80,
        },
        {
          field: "isGift",
          key: "isGift",
          title: "是否赠品",
          width: 50,
          formatter: ({ cellValue }) => {
            return cellValue ? "是" : "否";
          },
        },
        { field: "brandName", key: "brandName", title: "商品品牌", width: 80 },
        {
          field: "outNum",
          key: "outNum",
          title: "出库数量",
          width: 100,
        },
        {
          field: "remainNum",
          key: "remainNum",
          title: "剩余退货数量",
          width: 100,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            const value = this.$utils.sub(row.remainNum, row.returnNum);
            return <span>{value}</span>;
          },
        },
        {
          field: "returnNum",
          key: "returnNum",
          title: "退货数量",
          width: 100,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            const text = row[column.field];
            return (
              <van-field
                autocomplete="off"
                name="returnNum"
                value={text}
                type="number"
                on-input={(val) =>
                  this.inputChange(rowIndex, column.field, val)
                }
              ></van-field>
            );
          },
        },
        {
          field: "taxAmount",
          key: "taxAmount",
          title: "含税金额",
          align: "right",
          width: 80,
        },
        {
          field: "description",
          key: "description",
          title: "备注",
          width: 80,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            const text = row[column.field];
            return (
              <van-field
                autocomplete="off"
                name="description"
                value={text}
                type="number"
                on-input={(val) =>
                  this.inputChange(rowIndex, column.field, val)
                }
              ></van-field>
            );
          },
        },
        {
          field: "",
          key: "e",
          title: "操作",
          width: "",
          center: "left",
          fixed: "right",
          width: 50,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <span class="action">
                <van-icon
                  name="delete-o"
                  on-click={() => this.deleteRow(rowIndex)}
                ></van-icon>
              </span>
            );
          },
        },
      ],
    };
  },
  watch: {
    tableData: {
      handler(newVal, oldVal) {
        this.calcSum();
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    configApi.get().then((res) => {
      this.retailConfig = res.data;
    });
  },
  methods: {
    close() {
      this.$route.push({ path: "/retail/add" });
    },
    changeDiscountRate(index, key, value) {
      this.inputChange(index, key, value);
      const row = this.tableData[index];
      if (
        this.$utils.isFloatGeZero(row.discountRate) &&
        this.$utils.isFloatGtZero(row.retailPrice)
      ) {
        row.taxPrice = this.$utils
          .div(this.$utils.mul(row.retailPrice, row.discountRate), 100)
          .toFixed(2);
        this.inputChange(index, "taxPrice", row.taxPrice);
      }
    },
    taxPriceInput(index, key, value) {
      this.inputChange(index, key, value);
      const row = this.tableData[index];
      console.log(row);
      if (row.retailPrice && row.retailPrice !== 0) {
        if (this.$utils.isFloatGeZero(row.taxPrice)) {
          row.discountRate = this.$utils
            .mul(this.$utils.div(row.taxPrice, row.retailPrice), 100)
            .toFixed(2);
          this.inputChange(index, "discountRate", row.discountRate);
        }
      }
    },
    toShowProductSelect() {
      if (!this.form.scId) {
        Notify({ type: "warning", message: "请先选择仓库" });
        return;
      }
      this.showProductSelect = true;
    },
    genParams() {
      const params = {
        scId: this.form.scId,
        memberId: this.form.memberId,
        salerId: this.form.salerId || "",
        paymentDate: this.form.paymentDate || "",
        outSheetId: this.form.outSheetId,
        description: this.form.description,
        required: true,
        payTypes: this.$refs.payType.getTableData().map((t) => {
          return {
            id: t.payTypeId,
            payAmount: t.payAmount,
            text: t.text,
          };
        }),
        products: this.tableData
          .filter((t) => this.$utils.isIntegerGtZero(t.returnNum))
          .map((t) => {
            const product = {
              productId: t.productId,
              oriPrice: t.retailPrice,
              returnNum: t.returnNum,
              description: t.description,
            };

            if (t.isFixed) {
              product.outSheetDetailId = t.id;
            }

            return product;
          }),
      };
      return params;
    },
    addDays(date, days) {
      if (!date || !days) {
        return "";
      }
      const result = new Date(date);
      result.setDate(result.getDate() + Number(days));
      return moment(result).format("YYYY-MM-DD");
    },
    // 校验数据
    validData() {
      if (this.$utils.isEmpty(this.form.scId)) {
        this.$utils.createError("仓库不允许为空！");
        return false;
      }

      if (
        this.retailConfig.retailReturnRequireMember &&
        this.$utils.isEmpty(this.form.memberId)
      ) {
        this.$utils.createError("会员不允许为空！");
        return false;
      }

      if (this.form.allowModifyPaymentDate) {
        if (this.$utils.isEmpty(this.form.paymentDate)) {
          this.$utils.createError("付款日期不允许为空！");
          return false;
        }
      }

      if (this.$utils.isEmpty(this.form.outSheetId)) {
        this.$utils.createError("零售出库单不允许为空！");
        return false;
      }

      if (this.$utils.isEmpty(this.tableData)) {
        this.$utils.createError("请录入商品！");
        return false;
      }

      for (let i = 0; i < this.tableData.length; i++) {
        const product = this.tableData[i];

        if (this.$utils.isEmpty(product.productId)) {
          this.$utils.createError("第" + (i + 1) + "行商品不允许为空！");
          return false;
        }

        if (product.isGift) {
          if (parseFloat(product.taxPrice) !== 0) {
            this.$utils.createError("第" + (i + 1) + "行商品价格必须等于0！");
            return false;
          }
        } else {
          if (!this.$utils.isFloatGtZero(product.taxPrice)) {
            this.$utils.createError("第" + (i + 1) + "行商品价格必须大于0！");
            return false;
          }
        }

        if (!this.$utils.isNumberPrecision(product.taxPrice, 2)) {
          this.$utils.createError(
            "第" + (i + 1) + "行商品价格最多允许2位小数！"
          );
          return false;
        }

        if (!this.$utils.isEmpty(product.returnNum)) {
          if (!this.$utils.isInteger(product.returnNum)) {
            this.$utils.createError(
              "第" + (i + 1) + "行商品退货数量必须为整数！"
            );
            return false;
          }

          if (product.isFixed) {
            if (!this.$utils.isIntegerGeZero(product.returnNum)) {
              this.$utils.createError(
                "第" + (i + 1) + "行商品退货数量不允许小于0！"
              );
              return false;
            }
          } else {
            if (!this.$utils.isIntegerGtZero(product.returnNum)) {
              this.$utils.createError(
                "第" + (i + 1) + "行商品退货数量必须大于0！"
              );
              return false;
            }
          }

          if (product.isFixed) {
            if (product.returnNum > product.remainNum) {
              this.$utils.createError(
                "第" +
                  (i + 1) +
                  "行商品累计退货数量为" +
                  (product.outNum - product.remainNum) +
                  "，剩余退货数量为" +
                  product.remainNum +
                  "，本次退货数量不允许大于" +
                  product.remainNum +
                  "！"
              );
              return false;
            }
          }
        } else {
          if (!product.isFixed) {
            this.$utils.createError(
              "第" + (i + 1) + "行商品退货数量不允许为空！"
            );
            return false;
          }
        }
      }

      if (
        this.tableData.filter((item) =>
          this.$utils.isIntegerGtZero(item.returnNum)
        ).length === 0
      ) {
        this.$utils.createError("零售出库单中的商品必须全部或部分退货！");
        return false;
      }

      if (!this.$refs.payType.validData()) {
        return false;
      }

      const payTypes = this.$refs.payType.getTableData();
      const totalPayAmount = payTypes.reduce(
        (tot, item) => this.$utils.add(tot, item.payAmount),
        0
      );
      if (!this.$utils.eq(this.form.totalAmount, totalPayAmount)) {
        this.$utils.createError(
          "所有支付方式的支付金额不等于含税总金额，请检查！"
        );
        return false;
      }

      return true;
    },
    // 直接审核通过订单
    async toDirectApprovePass() {
      if (!this.validData()) {
        return;
      }
      const params = this.genParams();
      Dialog.confirm({
        title: "确认弹窗",
        message: "确定要直接审核通过该零售退货单吗",
      }).then(() => {
        this.loading = true;
        directApprovePass(params)
          .then((res) => {
            Toast.success(res.msg);
            this.$router.push({ path: "/retail/return" });
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },
    onAddConfirm() {
      if (!this.validData()) {
        return;
      }
      const params = this.genParams();
      create(params).then((res) => {
        Toast.success(res.msg);
        this.$router.push({ path: "/retail/return" });
      });
    },
    // 计算汇总数据
    calcSum() {
      let totalNum = 0;
      let giftNum = 0;
      let totalAmount = 0;

      this.tableData
        .filter((t) => {
          return (
            this.$utils.isFloatGeZero(t.taxPrice) &&
            this.$utils.isIntegerGeZero(t.returnNum)
          );
        })
        .forEach((t) => {
          const num = parseInt(t.returnNum);
          if (t.isGift) {
            giftNum = this.$utils.add(giftNum, num);
          } else {
            totalNum = this.$utils.add(totalNum, num);
          }

          totalAmount = this.$utils.add(
            totalAmount,
            this.$utils.mul(num, t.taxPrice)
          );
        });

      this.form.totalNum = totalNum;
      this.form.giftNum = giftNum;
      this.form.totalAmount = totalAmount;
    },
    showStockSelect() {
      this.stockSelectVisible = true;
    },
    stockSelectConfirm(row) {
      if (Object.keys(row).length === 0) {
        this.stockSelectVisible = false;
        return;
      }
      this.stockSelectVisible = false;
      this.form.scName = row.name;
      this.form.scId = row.id;
    },
    showSupplierSelect() {
      this.retailOutSelectVisible = true;
    },
    emptyProduct() {
      return {
        id: this.$utils.uuid(),
        productId: "",
        productCode: "",
        productName: "",
        skuCode: "",
        externalCode: "",
        unit: "",
        spec: "",
        categoryName: "",
        brandName: "",
        retailPrice: 0,
        taxPrice: "",
        discountRate: 100,
        outNum: "",
        remainNum: "",
        returnNum: "",
        taxRate: "",
        isGift: true,
        taxAmount: "",
        description: "",
        isFixed: false,
        products: [],
      };
    },
    retailOutSelectConfirm(row) {
      if (Object.keys(row).length === 0) {
        this.retailOutSelectVisible = false;
        return;
      }
      this.form = { ...row };
      this.tableData2 = [];
      console.log("没有id", row.id);
      getWithReturn(row.id)
        .then((res) => {
          debugger;
          const data = res.data;
          console.log(data, "getwithreturn");
          const tableData = this.tableData.filter((item) => !item.isFixed);
          let outSheetDetails = data.details || [];
          outSheetDetails = outSheetDetails.map((item) => {
            item.isFixed = true;
            return Object.assign(this.emptyProduct(), item);
          });

          this.tableData = [...outSheetDetails, ...tableData];

          this.form.scId = data.scId;
          if (data.memberId) {
            this.form.memberId = data.memberId;
            this.canChangeMember = this.$utils.isEmpty(this.from.memberId);
            this.memberChange(this.from.memberId);
          }

          if (!this.$utils.isEmpty(data.salerId)) {
            this.from.salerId = data.salerId;
          }

          getOrderPayType({ orderId: row.id }).then((res) => {
            console.log(res, "getOrderPayType");
            this.tableData2 = res.data;
          });
        })
        .finally(() => {
          this.loading = false;
        });

      this.retailOutSelectVisible = false;
      this.form.outSheetId = row.id;
    },
    memberChange(memberId) {
      getPaymentDate(memberId).then((res) => {
        this.from.paymentDate = res.data.paymentDate || "";
        this.from.allowModifyPaymentDate = res.data.allowModify;
      });
    },
    inputChange(rowIndex, field, val) {
      this.$set(this.tableData, rowIndex, {
        ...this.tableData[rowIndex],
        [field]: val,
      });
    },
    openDateTimePicker(type = "product", index) {
      this.datePickType = type;
      this.index = index;
      this.showDatePicker = true;
    },
    deleteRow(index) {
      this.tableData.splice(index, 1);
    },
    onSelectProductConfirm(row) {
      if (this.showProductSelect) {
        this.showProductSelect = false;
      }
      if (row.length) {
        row.forEach((el) => {
          this.tableData.push({
            ...el,
            taxRate: 0,
            taxCostPrice: 0.01,
            stockNum: 1,
            isGift: false,
            productionDate: "",
            shelfLifeDays: 0,
            shelfLife: "",
          });
        });
      }
    },
    onDatePickerConfirm(val) {
      if (!val) return;
      if (this.datePickType == "product") {
        this.$set(this.tableData, this.index, {
          ...this.tableData[this.index],
          productionDate: val,
        });
      } else {
        this.form[this.index] = val;
      }
      this.showDatePicker = false;
      this.index = 0;
    },
  },
};
</script>
<style scoped>
.van-cell {
  padding: 5px 0px 5px 17px;
}
#add {
  position: relative;
}
::v-deep .van-field__body {
  height: 100%;
}
.actions-btn {
  .van-button {
    margin: 5px 5px 0 0;
  }
}
.action {
  background-color: #fff;
  padding-left: 20px;
  display: flex;
  align-items: center;
  font-size: 26px;
  height: 36px;
}
</style>
