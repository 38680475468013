import requests from '../../request'

const baseUrl = '/retail/config';
const region = 'cloud-api';

/**
 * 根据ID查询
 * @param id
 */
export const get = (params) => {
    return requests({
        url: baseUrl,
        method: 'get',
        params
    },{
        region,
    }
    );
}

/**
 * 修改
 * @param data
 */
export const update = (params) => {
    return requests({
        url: baseUrl,
        method: 'put',
        params,
    },
        {
            region,
            contentType: 'application/x-www-form-urlencoded;charset=UTF-8',
        });
}